import React, { useState, useEffect } from 'react'

const RiverData = ({ station }) => {
  const [stationData, setStationData] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  let levelindicator = 'bob'

  useEffect(() => {
    const url = 'https://environment.data.gov.uk/flood-monitoring/id/stations/' + station + '.json'

    console.log('Mounting or updating')
    async function fetchData() {
      setLoading(true)
      setError()
      console.log('Fetching Data')
      const data = await fetch(url)
        .then((res) => res.json())
        .catch((err) => {
          setError(err)
        })
      setStationData(data)
      setLoading(false)
      console.log(data)
    }
    fetchData()
  }, [])

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error...</p>
  if (stationData.items.measures.latestReading.value < stationData.items.stageScale.typicalRangeHigh) {
    levelindicator = 'This level is currently Below the Normal Average'
  } else {
    levelindicator = 'This level is currently Higher than the Normal Average'
  }
  return (
    <>
      <h2>Latest Reading for {stationData.items.riverName}</h2>
      <b>Station ID : </b> {stationData.items.stationReference}
      <br />
      <b>Date : </b>
      {new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      }).format(new Date(stationData.items.measures.latestReading.dateTime))}
      <br />
      <b>Time : </b>{' '}
      {new Intl.DateTimeFormat('default', {
        hour: '2-digit',
        minute: '2-digit',
      }).format(new Date(stationData.items.measures.latestReading.dateTime))}
      <br />
      <b>Level : </b> {stationData.items.measures.latestReading.value} m <br />
      <b>Level Indicator : </b> {levelindicator}
      <br />
      <br />
      <b>Typical High Level : </b> {stationData.items.stageScale.typicalRangeHigh} m
      <br />
      <b>Maximum Level on Record : </b> {stationData.items.stageScale.maxOnRecord.value} m on{' '}
      {new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      }).format(new Date(stationData.items.stageScale.maxOnRecord.dateTime))}{' '}
      <br />
    </>
  )
}

export default RiverData
